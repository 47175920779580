<template>
    <div class="mobile-content">
        <div class="news-detail-m-box">
            <div class="d-flex d-flex-items-center d-flex-between">
                <div class="color-white f-18">{{info.title || ""}}</div>
            </div>
            <div class="color-999 f-14 m-t-20">发布者：{{info.send_user}}  {{info.createtime}}</div>
            <div class="m-t-30 color-white lh-25" v-html="info.content">

            </div>
        </div>
    </div>
</template>
<script>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
import baseRequest from '@/service/index'

export default defineComponent({
    setup(){
        const route = useRouter()
        const info = ref({})
        console.log(route.currentRoute.value.query.id)
        const getDetail = (id) =>{
            baseRequest.post({
                url: 'api/index/article_detail',
                data:{
                    article_id:id
                }
            }).then(res=>{
                console.log(res)
                info.value = res.data
            })
        }
        getDetail(route.currentRoute.value.query.id)
        const jump = () =>{
            route.push('/news')
        }
        return{
            jump,info
        }
    }
});
</script>
