import { ElMessage } from 'element-plus'
class Message {
    notice(text:string){
        ElMessage(text)
    }
    success(text:string){
        ElMessage({
            message: text,
            type: 'success',
        })
    }
    warning(text: string){
        ElMessage({
            message: text,
            type: 'warning',
        })
    }
    error(text:string){
        ElMessage.error(text)
    }
}
export default new Message()
