<template>
    <div class="group-bg-box">
        <div class="news-detail-box">
           <div class="new-detail-content">
                <div class="d-flex d-flex-items-center d-flex-between">
                    <div class="color-white f-22">{{info.title || ""}}</div>
                    <div class="more-btn" @click="jump">更多新闻 》</div>
                </div>
                <div class="color-999 f-16 m-t-20 d-flex-between">
                    发布者：{{info.send_user}}
                    <span>{{info.createtime}}</span>
                </div>
                <div class="m-t-30 color-white lh-35" v-html="info.content">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { defineComponent,ref } from 'vue'
import { useRouter } from 'vue-router'
import baseRequest from '@/service/index'

export default defineComponent({
    setup(){
        const route = useRouter()
        const info = ref({})
        console.log(route.currentRoute.value.query.id)
        const getDetail = (id) =>{
            baseRequest.post({
                url: 'api/index/article_detail',
                data:{
                    article_id:id
                }
            }).then(res=>{
                console.log(res)
                info.value = res.data
            })
        }
        getDetail(route.currentRoute.value.query.id)
        const jump = () =>{
            route.push('/news')
        }
        return{
            jump,info
        }
    }
});
</script>


<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
