<template>
    <div>
        <div class="bottom-box">
            <div class="f-16">
                <div>咨询电话</div>
                <div class="m-t-15">0431-83444444 / 15764363945</div>
                <div class="m-t-15">吉林省长春市南关区解放大路810号长春国际商务中心A2区15F</div>
                <div class="m-t-15 ba" @click="jump('https://beian.miit.gov.cn')">备案号：吉ICP备17002063号-1</div>
            </div>
            <div class="d-flex f-14">
                <div class="m-r-20">
                    <img src="@/assets/image/code-1.jpg" class="w-80 h-80">
                    <div class="align-center m-t-5">集团公众号</div>
                </div>
                <div>
                    <img src="@/assets/image/code-3.jpg" class="w-80 h-80">
                    <div class="align-center m-t-5">联系人微信</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { defineComponent,ref } from 'vue'
import baseRequest from '@/service/index'
import { useRouter } from 'vue-router'
export default defineComponent({
    setup(){
        const router = useRouter()
        const jump = (url) =>{
            window.open(url)
        }
        return{
            jump
        }
    }
});
</script>
<style scoped>
.ba{
    cursor: pointer;
}
</style>
